import { createStore } from 'vuex'
import 'es6-promise'
import {Promise} from "es6-promise";
import teacher_store from "@/store/modules/teacher";
import student_store from "@/store/modules/student";
import challenge_store from "@/store/modules/challenge";
import challenge_type_store from "@/store/modules/challenge_type";

const axios = require('axios');

const instance = axios.create({
    // localBaseURL: 'http://127.0.0.1:8000/api',
    // onlineBaseURL: 'https://epsasport-dev.apps.lu/api',
    // baseURL: 'https://epsasport-dev.apps.lu/api',
    baseURL:  process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})
const store = createStore({
    modules: {
        teacher_store,
        student_store,
        challenge_store,
        challenge_type_store
    },

    state: {
        status: '',
        data:{
            role: '',
            email: ''
        },
        access_token: '',

        school:{
            id: -1,
            name: '',
            email: '',
            address: '',
            mobile: ''
        },


        allStoreClasses: '',
        allStoreClassesPagination:[],
        storeClasse: '',
        currentClasse_id: '',
        currentClasse: '',
        currentSchool: '',
        classes:"",
        classList: "",
        studentMeta:{},
        classMeta:{},
        user:{}

    },
    mutations: {
        setStatus: function (state, status){
            this.state.status = status;
        },
        logUser: function (state, user){
            this.state.user = user;
        },
        school: function (state, school){
            state.school = school;
        },
        setClasses: function (state, classes){
            state.allStoreClasses = classes.data;
        },
        setClassesPagination: function (state, classes){
            state.allStoreClassesPagination = classes.data;
            state.classMeta = classes.meta;
        },
        setSchool: function (state, school){
            state.currentSchool = school;
        },
        setClasse: function (state, classe){
            state.storeClasse = classe.data;
        },


    },
    actions: {
        loginAdmin:  ({commit}, userInfos) => {
            commit('setStatus', 'loading');
            return new Promise((resolve, reject) => {

                instance.post('/admin/login',userInfos)
                    .then(function (response){
                        commit('setStatus', 'created');
                        commit('logUser', response.data);
                        localStorage.setItem('token', response.data.data.access_token);
                        localStorage.setItem('role', response.data.data.role);
                        resolve(response);
                    })
                    .catch(function (error){
                        commit('setStatus', 'error_login');
                        reject(error);
                    });
            });

        },
        login:  ({commit}, userInfos) => {
            commit('setStatus', 'loading');
            return new Promise((resolve, reject) => {

                console.log(userInfos);
                instance.post('/student/login',userInfos)
                    .then(function (response){
                        commit('setStatus', 'created');
                        commit('logUser', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        commit('setStatus', 'error_login');
                        reject(error);
                    });
            });

        },
        logoutAction:  ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            commit('setStatus', 'loading');
            return new Promise((resolve, reject) => {
                instance.get('/logout',config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        console.log(error, 'erreur deconnexion: '+config.headers.Authorization);
                        localStorage.setItem('token', '');
                        localStorage.setItem('role', '');
                        reject(error);
                    });
            });

        },
        profile:  ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get('/user',config)
                    .then(function (response){
                        commit('logUser', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        createAccount: ({commit}, userInfos) => {
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/student/register',userInfos)
                    .then(function (response){
                        commit('setStatus', 'student_created');
                        resolve(response);
                    })
                    .catch(function (error){
                        
                        commit('setStatus', 'error_created');
                       reject(error);
                    });
            });

        },
        updateProfile: ({commit}, userInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/student/profile',userInfos, config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        saveSchool: ({commit}, schoolInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                if(localStorage.getItem('school_id') == 0){

                    instance.post('/school/create',schoolInfos, config)
                        .then(function (response){
                            commit('setStatus', 'school_created');
                            resolve(response);
                        })
                        .catch(function (error){
                            reject(error);
                        });
                }
                else{
                    instance.put('/school/update/'+localStorage.getItem('school_id'),schoolInfos, config)
                        .then(function (response){
                            commit('setStatus', 'school_created');
                            resolve(response);
                        })
                        .catch(function (error){
                            reject(error);
                        });
                }

            });

        },

        deleteClasse: ({commit}, classeInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.delete('/classe/delete/'+classeInfos.classe_id, config)
                    .then(function (response){
                        commit('setClasses', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },

        saveClassAction: ({commit}, classInfos) => {

            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                console.log(classInfos, 'on es ici');
                    instance.post('/classe/create',classInfos, config)
                        .then(function (response){
                            commit('setClasses', response.data);
                            resolve(response);
                        })
                        .catch(function (error){
                            reject(error);
                        });
            });

        },
        getSchoolInfos: ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {

                instance.get('/school_info', config)
                    .then(function (response){
                        localStorage.setItem('school_name', response.data.data.name);
                        localStorage.setItem('school_mobile', response.data.data.mobile);
                        localStorage.setItem('school_address', response.data.data.address);
                        localStorage.setItem('school_email', response.data.data.email);
                        commit('setSchool', response.data.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        commit('setStatus', 'error_login');
                        reject(error);
                    });
            });
        },

        getClassesList: ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get('/classes/list', config)
                    .then(function (response){
                        commit('setClasses', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        if(error.request.status == 401){
                            localStorage.setItem('token', '');
                        }
                        commit('setClasses', 'error_get_classes');
                        reject(error);
                    });
            });
        },
        getClassesListPagination: ({commit}, data) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            data.search = data.search ? data.search : '';

            return new Promise((resolve, reject) => {
                instance.get(`/classes/listPagination?page=${data.page}&search=${data.search}`, config)
                    .then(function (response){
                        commit('setClassesPagination', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        if(error.request.status == 401){
                            localStorage.setItem('token', '');
                        }
                        commit('setClasses', 'error_get_classes');
                        reject(error);
                    });
            });
        },
        getClasse: ({commit},payload) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                const currentClasseId = payload.currentClasseId ? payload.currentClasseId : this.$store.state.currentClasse_id;
                const url='/classe/'+currentClasseId;

                instance.get(url, config)
                    .then(function (response){
                        commit('setClasse', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        commit('setClasse', 'error_get_classe');
                        reject(error);
                    });
            });
        },
        resetPassword: ({commit}, userInfos) => {
           return new Promise((resolve, reject) => {
           instance.post('/reset-password',userInfos)
               .then(function (response){
                   resolve(response);
               })
               .catch(function (error){
                   commit('setStatus', 'error_login');
                   reject(error);
               });
           });
        },
        forgotPassword: ({commit}, userInfos) => {
            return new Promise((resolve, reject) => {
                instance.post('/forgot-password',userInfos)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        commit('setStatus', 'error_login');
                        reject(error);
                    });
            });
        },

    }
})

export default store;