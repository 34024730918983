import {Promise} from "es6-promise";
import 'es6-promise'

const axios = require('axios');

const instance = axios.create({
    // localBaseURL: 'http://127.0.0.1:8000/api',
    // onlineBaseURL: 'https://epsasport-dev.apps.lu/api',
    // baseURL: 'https://epsasport-dev.apps.lu/api',
    baseURL:  process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})
const challenge_store =  {
    namespaced: true,
    state: {
        allStoreChallenges: '',
        allStoreChallengesPagination: [],
        meta: {},
    },

    mutations: {
        setChallenge: function (state, challenge){
            state.allStoreChallenges = challenge.data;
        },
        setChallengePagination: function (state, challenge){
            state.allStoreChallengesPagination = challenge.data;
            state.meta = challenge.meta
        },
    },

    actions: {
        saveChallenge: ({commit}, challengeInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/challenge/create',challengeInfos, config)
                    .then(function (response){
                        commit('setChallenge', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        deleteChallenge: ({commit}, challengeInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.delete('/challenge/delete/'+challengeInfos.challenge_id, config)
                    .then(function (response){
                        commit('setChallenge', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },

        getChallengeList: ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get('/challenge/list_all', config)
                    .then(function (response){
                        commit('setChallenge', response.data);
                        resolve(response);
                    })
                    .catch(function (error){

                        commit('setChallenge', 'error_get_challenges');
                        reject(error);
                    });
            });
        },
        getChallengeListPagination: ({commit}, data) => {
            data.start = data.start ? data.start : '';
            data.end = data.end ? data.end : '';
            data.search = data.search ? data.search : '';
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/challenge/listPagination?page=${data.page}&start=${data.start}&end=${data.end}&search=${data.search}`, config)
                    .then(function (response){
                        commit('setChallengePagination', response.data);
                        resolve(response);
                    })
                    .catch(function (error){

                        commit('setChallenge', 'error_get_challenges');
                        reject(error);
                    });
            });
        },
        exportExcel: (data) => {
            data.start = data.start ? data.start : '';
            data.end = data.end ? data.end : '';
            data.search = data.search ? data.search : '';
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/challenge/exportExcel?start=${data.start}&end=${data.end}&search=${data.search}`, config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        }
    }
}

export default challenge_store;

