<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center; height: auto">

  <div class="container insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div v-if="role !=='student'" class="col-lg-3 col-md-4 col-sm-12 ml-auto" style="float: left">

              <div class="card card-login card-hidden">
                <div class="card-header card-header-primary text-center">
                  <span class="card-title">Challenges</span>
                </div>
                <div class="card-body ">
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">face</i>
                      </span>
                    </div>
                    <input v-model="name" type="text" class="form-control" placeholder="Name...">
                  </div>
                </span>
                <div class="row" style="margin-top: 1em;">
                  <div class="col-lg-4 col-md-4 col-sm-12">
                    <label class="label-select">Type</label>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-12">
                    <select class="custom-select" v-model="type" data-style="select-with-transition" title="Choose a Type" data-size="7">
                      <option disabled>Choose type</option>
                      <option v-for="item in this.$store.state.challenge_type_store.allStoreChallengeType" v-bind:key="item.id" :value="item.id">
                       {{ item.name }}
                      </option>
                    </select>

                  </div>
                </div>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">social_distance</i>
                      </span>
                    </div>
                    <input type="number" min="0" v-model="distance" class="form-control" placeholder="Distance...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group ">
                    <div class="input-group-prepend d-flex align-items-end">
                      <span class="input-group-text">
                        <i class="material-icons">schedule</i>
                      </span>
                      <label>Duration From</label>
                    </div>

<!--                    <input type="number"  v-model="duration" class="form-control" placeholder="Duration...">-->
                      <Datepicker utc="preserve" id="duration-from" :minDate="new Date()" class="ml-2 mt-1"  v-model="durationFrom"></Datepicker>
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend d-flex align-items-end">
                      <span class="input-group-text">
                        <i class="material-icons">schedule</i>
                      </span>
                      <label>Duration To</label>
                    </div>
                      <Datepicker  utc="preserve" id="duration-to" :minDate="new Date()" class="ml-2 mt-1" v-model="durationTo"></Datepicker>
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">group_add</i>
                      </span>
                    </div>
                    <input type="number" min="0" v-model="participation" class="form-control" placeholder="Pts Participation...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">military_tech</i>
                      </span>
                    </div>
                    <input type="number" min="0" v-model="podium" class="form-control" placeholder="Pts Podium...">
                  </div>
                </span>
                  <label class="label-select" style="margin-top: 1em">Enseignant</label>
                  <span class="bmd-form-group">

                    <select class="custom-select" v-model="teacher" data-style="select-with-transition" label="teacher" title="Choose a Teacher" data-size="7">
                      <option disabled>Choose teacher</option>
                      <option v-for="item in this.$store.state.teacher_store.allStoreTeachers" v-bind:key="item.id" :value="item.id">
                       {{ item.name }}
                    </option>
                    </select>
                  </span>
                  <label class="label-select" style="margin-top: 1em">Classes</label>
                  <span class="bmd-form-group">
                      <Multiselect
                          class="ml-2"
                          mode="tags"
                          label="name"
                          v-model="myclasse"
                          :close-on-select="false"
                          :options="allStoreClasses"
                      />
<!--                    <select class="custom-select" v-model="myclasse" data-style="select-with-transition" title="Choose a Class" data-size="7">-->
<!--                      <option disabled>Choose class</option>-->
<!--                      <option v-for="item in this.$store.state.allStoreClasses" v-bind:key="item.id" :value="item.id">-->
<!--                       {{ item.name }}-->
<!--                      </option>-->
<!--                    </select>-->
                  </span>
                </div>
                <div class="card-footer justify-content-center" style="margin-top: 2em">
                  <button @click="saveChallenge()" class="btn btn-rose btn-lg">CREATE NEW CHALLENGE</button>
                </div>

              </div>
          </div>
          <div class="" :class="[role =='student' ? 'col-12' : 'col-lg-9 col-md-9 col-sm-12']" style="float: left">
            <div class="table-responsive">

              <div class="d-flex align-items-end">
                <Datepicker  id="date-start" class="mt-2"  modelType="yyyy-MM-dd" :enableTimePicker="false"  v-model="start" placeholder="Select start Date"></Datepicker>
                <Datepicker  id="date-end" class="ml-2 mt-2"  modelType="yyyy-MM-dd" :enableTimePicker="false" v-model="end" placeholder="Select end Date"></Datepicker>
                <input v-model="search" type="text" class="form-control ml-3 mt-0" placeholder="Search by teacher, student or name ">
                <a class="btn btn-rose  ml-5 mt-3 mb-0" :href="`${VUE_APP_API_BASE_URL}/challenge/exportExcel?start=${start}&end=${end}&search=${search}`" role="button">Export</a>
              </div>

              <table class="table">
                <thead>
                <tr>
                  <th class="text-center text-white">#</th>
                  <th class="text-center text-white">Name</th>
                  <th class="text-center text-white">Teacher</th>
                  <th class="text-center text-white">Class</th>
                  <th class="text-center text-white">Participation</th>
                  <th class="text-center text-white">Podium</th>
                  <th class="text-center text-white">Duration From</th>
                  <th class="text-center text-white">Duration To</th>
                  <th class="text-right text-white">Actions</th>
                </tr>
                </thead>
                <tbody>

                <tr  v-for="(item , index) in this.$store.state.challenge_store.allStoreChallengesPagination" :key="`challenge-${item.id}`">
                  <td class="text-center text-white">{{(index+1)+(page-1)*10}}</td>
                  <td class="text-center text-white">{{item.name}}</td>
                  <td class="text-center text-white" v-if="item.teacher != null">{{item.teacher.name}}</td>
                  <td class="text-center text-white" v-else>None</td>
                  <td class="text-center text-white cursor-pointer">
                    <div v-for="(classe, classIndex) in item.classes" :key="`class-${classIndex}`"  @click="openClass(classe.id)">{{classe.name}}</div>
                  </td>
                  <td class="text-center text-white">{{item.pts_participation}}</td>
                  <td class="text-center text-white">{{item.pts_podium}}</td>
                  <td class="text-center text-white">{{item.duration_from}}</td>
                  <td class="text-center text-white">{{item.duration_to}}</td>
                  <td class="td-actions text-right actions-box">
                    <button type="button" rel="tooltip" class="btn btn-success btn-just-icon btn-sm mr-2" @click="openRanking(item.id)">
                      <i class="material-icons">trending_up</i>
                    </button>
                    <button v-if="role=='admin'" type="button" @click="deleteChallenge(item.id)" rel="tooltip" class="btn btn-danger btn-just-icon btn-sm" style="margin-left: 2px">
                      <i class="material-icons">close</i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <v-pagination
                v-if="this.$store.state.challenge_store.meta.last_page>1"
                id="pagination"
                v-model="page"
                :pages="this.$store.state.challenge_store.meta.last_page"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="updatePageHandler"
            />
          </div>
          <modal-message @close="closeModal" :variant="variant" :open-close="openClose" >
            <p class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" :fill="msgColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg></p>
            <p class="text-center h4 text-dark">{{msg}}</p>
          </modal-message>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import MyNavMenu from "@/components/MyNavMenu";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ModalMessage from "@/components/ModalMessage";
import Multiselect from '@vueform/multiselect'

export default {
  name: "ChallengeView",

  components: {
    ModalMessage,
    MyNavMenu,
    Datepicker,
    VPagination,
    Multiselect
  },
  data() {
    return {
      VUE_APP_API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
      role: localStorage.role,
      name:'',
      duration: null,
      durationFrom:null,
      durationTo:null,
      participation: null,
      podium: null,
      distance: null,
      type: null,
      teacher: null,
      myclasse: [],
      page:1,
      start:'',
      end:'',
      search: '',
      openClose:false,
      msg:"",
      variant:"success",
      msgColor: "#47a44b",
      allStoreClasses: []

    }
  },

  mounted: function() {

    const self = this;

    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

    if(this.role !== 'student') {
      this.$store.dispatch('getClassesList').then(function () {
        self.$store.state.allStoreClasses.forEach((itme)=>{
          self.allStoreClasses.push({ value: itme.id, name: itme.name });
        });
      }, function (error) {
        console.log("No student registered: " + error)
      });

      this.$store.dispatch('teacher_store/getTeacherList').then(function () {
      }, function (error) {
        console.log(error)
      });
    }

    this.$store.dispatch('challenge_type_store/getChallengeTypeList').then(function (){
    }, function (error){
      console.log(error)
    });

    this.getChallengeListPagination(this.page);
  },
  watch:{
    start: function(){
      this.getChallengeListPagination(this.page);
    },
    end: function(){
      this.getChallengeListPagination(this.page);
    },
    search: function(){
      this.getChallengeListPagination(this.page);
    }
  },
  methods: {
    deleteChallenge: function(id) {
      const self = this;
      if(confirm("Do you really want to delete Challenge ?")) {
      self.$store.dispatch('challenge_store/deleteChallenge', {challenge_id: id}).then(function () {
        self.page = 1;
        self.getChallengeListPagination(self.page);
        self.msg= "Challenge deleted successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function () {
      });
    }
    },
    openClass: function(id){
      if (this.role !== 'student') {
        const url = '/classe/' + id;
        this.$router.push(url);
      }
    },
    exportExcel: function(){
      this.$store.dispatch('challenge_store/exportExcel', { start: this.start, end: this.end, search: this.search}).then(function (res){
        console.log(res, 'For File');
      });
    },
    saveChallenge: function (){
      console.log(this.myclasse,"myclass");
      const self = this;
      self.$store.dispatch('challenge_store/saveChallenge', {
        name: this.name,
        duration_from: this.durationFrom,
        duration_to: this.durationTo,
        pts_participation: this.participation,
        pts_podium: this.podium,
        distance: this.distance,
        type:this.type,
        teacher_id: this.teacher,
        room_ids: this.myclasse,
      }).then(function (){
        self.page = 1;
        self.getChallengeListPagination(self.page);
        self.name = '';
        self.durationFrom = null;
        self.durationTo = null;
        self.participation = null;
        self.podium = null;
        self.distance = null;
        self.type = null;
        self.teacher = null;
        self.myclasse = null;

        self.msg= "Challenge created successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error){
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;

        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });
    },
    getChallengeListPagination(page){
      this.$store.dispatch('challenge_store/getChallengeListPagination', {page: page, start: this.start, end: this.end, search: this.search}).then(function (){
      }, function (error){
        console.log("No challenge registered: "+error)
      });
    },
    updatePageHandler(page){
      this.getChallengeListPagination(page);
    },
    closeModal(){
      this.openClose=false;
    },
    openRanking(id){
      const url = `/ranking/challenges/${id}`;
      this.$router.push(url);
    }
  },
  computed: {
    ...mapActions([
      "challenge_store/getChallengeList",
        "challenge_store/saveChallenge",
    ]),

  }
}
</script>

<style scoped>

.actions-box{
  width: 90px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
#duration-from .dp__input,
#duration-to  .dp__input,
#date-start .dp__input,
#date-end .dp__input {
  color: #aaa;
}
</style>